<template>
    <el-dialog :title='td("店铺库存明细")' :append-to-body="true" :visible="updateIntoVisible" @close="onClose()" width="700px">
        <el-table :data="tableData" :row-class-name="rowWmsInOrderDetailIndex" tooltip-effect="dark" border style="margin-top: 20px;">
            <el-table-column :label="td('序号')" align="center" prop="index" width="100"/>
            <el-table-column prop="shopName" :label="td('店铺名称')" align="center"></el-table-column>
            <el-table-column prop="instockNum" :label="td('数量')" align="center"></el-table-column>
        </el-table>
        <span slot="footer" class="footet">
			<el-button @click="onClose()" type="primary" plain>{{td('取消')}}</el-button>
		</span>
    </el-dialog>
</template>

<script>
    import {
        getDetailShop
    } from "@/api/warehouse"

    export default {
        name: "shopInventoryDetail",
        data() {
            return {
                updateIntoVisible: false,
                tableData: [],
            }
        },
        props: ["updateorderList", "warhoureleList", "logisticsLists", "getinorder"],
        methods: {
            show(detailId){
                this.updateIntoVisible = true
                getDetailShop(detailId).then(res =>{
                    this.tableData = res.data;
                })
            },
            onClose() {
                this.updateIntoVisible = false;
            },
            rowWmsInOrderDetailIndex({
                                         row,
                                         rowIndex
                                     }) {
                row.index = rowIndex + 1;
            },
        }
    };
</script>

<style scoped>
    ::v-deep .el-dialog {
        margin: 10vh auto !important;
    }

    ::v-deep .el-dialog__body {
        padding: 5px 0;
        height: 55vh;
        overflow: auto;
    }

    .el-col {
        height: 40px;
        margin-bottom: 20px;
    }

    .msgConRowf {
        height: 250px;
        padding-top: 8px;
    }
</style>
