<template>
	<el-dialog :title='td("入库订单详情 ") + updateorderList.billNo' :visible="updateIntoVisible" @close="onClose()" width="1000px">
		<el-form label-position="top" label-width="115px" style="margin-left: 20px;" disabled>
			<el-row class="msgConRowf">
				<el-col :span="6">
					<el-form-item :label="td('物流公司')">
						<el-input @dblclick.native="dblclickLogistic" v-if="!logisticShow" v-model="updateorderList.logisticsCompany" :placeholder="td('请输入物流公司名称')" style="width:96%"/>
						<el-select @dblclick.native="dblclickLogistic" v-if="logisticShow" v-model="updateorderList.logisticsCompany" :placeholder='td("请选择物流公司")' style="width:96%">
							<el-option v-for="item in logisticsLists" :key="item.id" :label="td(item.shortName)"
									   :value="td(item.shortName)" />
						</el-select>
					</el-form-item>
					<el-form-item :label='td("收货仓库")' prop="receDepot" label-width="125px">
						<el-select v-model="updateorderList.receDepot" :placeholder="td('请选择收货仓库')" style="width:96%" clearable>
							<el-option v-for="(item,index) in warhoureleList" :key="index" :label="td(item.depotName)"
									   :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="6">
					<el-form-item :label="td('物流单号')">
						<el-input style="width:96%" v-model="updateorderList.wayBillNo" :placeholder="td('请输入物流单号')"></el-input>
					</el-form-item>
					<el-form-item :label='td("派送方式")' prop="dispatchDf" label-width="125px">
						<el-select v-model="updateorderList.dispatchDf" :placeholder="td('请选择派送方式')" style="width:96%" clearable>
							<el-option v-for="dict in tf('ERP_INORDER_DISPATCH')" :key="dict.detailId" :label="td(dict.langTranslate)"
									   :value="dict.detailId" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label="td('入库日期')">
						<el-date-picker v-model="updateorderList.inTime" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" :start-placeholder="td('开始日期')"
							:end-placeholder="td('截止日期')" style="width:96%" :placeholder="td('请选择入库日期')">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="td('入库类型')" prop="inorderType" label-width="125px">
						<el-select style="width:96%" v-model="updateorderList.inorderType" :placeholder="td('请选择入库类型')" clearable>
							<el-option v-for="item in tf('WMS_INORDER_TYPE')" :key="item.detailId"
									   :label="td(item.langTranslate)" :value="item.detailId" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label="td('存货仓库')">
						<el-select v-model="updateorderList.depotId" :placeholder="td('请选择仓库')" style="width:96%">
							<el-option v-for="(item, index) in warhoureleList" :key="index" :label="td(item.depotName)"
									   :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="td('备注')" style="width:96%">
						<el-input v-model="updateorderList.remark" :rows="3" :placeholder="td('请输入备注')"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item :label='td("上传图片")'>
				<ImageUpload v-model="updateorderList.inorderUrls" pictureType="picture-card" :limit="6" disabled/>
				<!-- <div v-for="item in inorderUrls" style="display: inline-block;">
					<el-image 
						style="width: 150px; height: 150px;border-radius: 10px;margin-right: 10px;"
						:src="item"
						:preview-src-list="inorderUrls">
					</el-image>
				</div> -->
			</el-form-item>
			<!-- <el-row class="intoCon">
        <el-form-item label="修改入库商品" label-width="120px"></el-form-item>
      </el-row> -->

			<el-table :data="tableData" tooltip-effect="dark" border style="margin-top: 20px;">
				<el-table-column prop="goodsName" :label="td('商品名称')" align="center"></el-table-column>
				<el-table-column prop="sellerSku" :label="td('Seller SKU')" align="center">
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="openShop(scope.row.detailId)">
							{{ scope.row.sellerSku }}
						</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="instockNum" :label="td('数量')" align="center">
					<!-- <template slot-scope="scope">
            <el-input style="width: 235px" v-model="scope.row.instockNum" />
          </template> -->
				</el-table-column>
				<el-table-column prop="remark" :label="td('备注')" align="center">
					<!-- <template slot-scope="scope">
            <el-input
              type="textarea"
              style="width: 235px"
              v-model="scope.row.remark"
            />
          </template> -->
				</el-table-column>
			</el-table>
		</el-form>
		<span slot="footer" class="footet">
			<el-button @click="onClose()" type="primary" plain>{{td('取消')}}</el-button>
			<el-button type="primary" style="margin-right: -6px;" @click="onClose()">{{td('确定')}}
			</el-button>
		</span>
		<shop-inventory-detail ref="shopInventory"></shop-inventory-detail>
	</el-dialog>
</template>

<script>
	import {
		updateInOrder
	} from "@/api/warehouse"
	import ShopInventoryDetail from "./shopInventoryDetail";
	import ImageUpload from "@/utils/ImageUpload/index.vue";
	export default {
		name: "updateInto",
		components: {ShopInventoryDetail,ImageUpload},
		data() {
			return{
				// 是否显示物流名称下拉框
				logisticShow: true,
			}
		},
		computed:{
			inorderUrls(){
				if(!this.updateorderList.inorderUrls) return;
				let urls = this.updateorderList.inorderUrls.split(',');
				if(!urls || !urls.length) return []
				return urls
			}
		},
		props: ["updateIntoVisible", "updateorderList", "warhoureleList", "tableData", "logisticsLists", "getinorder"],
		methods: {
			/** 物料公司输入框、下拉框双击时间触发 */
			dblclickLogistic() {
				this.logisticShow = !this.logisticShow
			},
			onClose() {
				this.$emit("update:updateIntoVisible", false);
			},
			comit(data) {
				console.log(data,'data');
				updateInOrder(data).then((res) => {
					this.$emit("update:updateIntoVisible", false);
					this.$message.success(this.td("修改成功"));
					this.getinorder()
				});
			},
			/**
			 * 打开库存明细弹窗
			 * @param detailId
			 */
			openShop(detailId) {
				this.$refs['shopInventory'].show(detailId);
			},
			edit() {
				this.logisticShow = true
			}
		}
	}
</script>

<style scoped>
	::v-deep .el-dialog {
		margin: 10vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 5px 0;
		height: 55vh;
		overflow: auto;
	}

	.el-col {
		height: 40px;
		margin-bottom: 20px;
	}

	.msgConRowf {
		height: 250px;
		padding-top: 8px;
	}
</style>
