import request from '@/utils/request'
import { getToken } from "@/utils/auth"

// 订单列表
export function getstatus(language,categoryId) {
  return request({
    url: '/tms/term/categoryDetail/' + language +'/'+categoryId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    // params : query
  })
}


