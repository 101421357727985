<template>
	<el-dialog :title='td("新增采购入库")' :visible="addIntoVisible" @close="onClose()" width="1200px" append-to-body
		:close-on-click-modal="false">
		<el-form ref="forms" :rules="rules" :model="form" label-position="top" label-width="120px"
			style="margin-left: 20px;">
			<el-col :span="6">
				<el-form-item :label='td("物流公司")' prop="logisticsCompany">
					<el-input @dblclick.native="dblclickLogistic" v-if="!logisticShow" v-model="form.logisticsCompany" :placeholder="td('请输入物流公司名称')" style="width:96%"/>
					<el-select @dblclick.native="dblclickLogistic" v-if="logisticShow" v-model="form.logisticsCompany" :placeholder='td("请选择物流公司")' style="width:96%">
						<el-option v-for="item in logisticsLists" :key="item.id" :label="td(item.shortName)"
							:value="td(item.shortName)" />
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :span="6">
				<el-form-item :label='td("物流单号")' prop="wayBillNo" label-width="125px">
					<el-input style="width:96%" v-model="form.wayBillNo" :placeholder='td("请输入物流单号")'></el-input>
				</el-form-item>
			</el-col>

			<el-col :span="6">
				<el-form-item :label='td("入库日期")' prop="inTime">
					<el-date-picker default-time="08:00:00" v-model="form.inTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
						range-separator="至" start-placeholder="开始日期" end-placeholder="截止日期" style="width:96%" :placeholder="td('请选择入库日期')">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item :label='td("存货仓库")' prop="depotId" label-width="125px">
					<el-select v-model="form.depotId" :placeholder="td('请选择存货仓库')" style="width:96%" clearable>
						<el-option v-for="(item,index) in warhoureleList" :key="index" :label="td(item.depotName)"
								   :value="item.id" />
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item :label='td("收货仓库")' prop="receDepot" label-width="125px">
					<el-select v-model="form.receDepot" :placeholder="td('请选择收货仓库')" style="width:96%" clearable>
						<el-option v-for="(item,index) in warhoureleList" :key="index" :label="td(item.depotName)"
								   :value="item.id" />
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item :label='td("派送方式")' prop="dispatchDf" label-width="125px">
					<el-select v-model="form.dispatchDf" :placeholder="td('请选择派送方式')" style="width:96%" clearable>
						<el-option v-for="dict in tf('ERP_INORDER_DISPATCH')" :key="dict.detailId" :label="td(dict.langTranslate)"
								   :value="dict.detailId" />
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item :label="td('入库类型')" prop="inorderType" label-width="125px">
					<el-select style="width:96%" v-model="form.inorderType" :placeholder="td('请选择入库类型')" clearable>
						<el-option v-for="item in tf('WMS_INORDER_TYPE')" :key="item.detailId"
								   :label="td(item.langTranslate)" :value="item.detailId" />
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item :label='td("备注")'>
					<el-input v-model="form.remark" :show-word-limit='true' style="width:96%" :placeholder='td("请输入备注")'></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				<el-form-item :label='td("上传图片")'>
					<ImageUpload v-model="form.inorderUrls" pictureType="picture-card" :limit="6"/>
				</el-form-item>
			</el-col>
			<el-col :span="12" style="margin:0 0 -12px 0; font-size: 20px">
				<h4>{{td("入库明细")}}</h4>
			</el-col>

			<!-- 表格 -->

				<el-table :key="tableKey" :data="form.instockDetailList" :row-class-name="rowWmsInOrderDetailIndex"
				@selection-change="AddSonlist" tooltip-effect="dark" border
				:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
				<el-table-column type="selection" width="50" align="center" />
				<el-table-column :label="td('序号')" align="center" prop="index" width="100" />
				<el-table-column :label="td('seller Sku')" width="250" align="center">
					<template slot-scope="scope">
						<el-select v-model="scope.row.sonSku" @change="chanSelect(scope.row)"
							:placeholder='td("请选择seller Sku")' filterable style="width:220px">
							<el-option v-for="(item,index) in tableDataa" :key="index"
								:label="`${item.sellerSku} / ${item.goodsName}`" :value="item.sonSku" />
						</el-select>
					</template>
				</el-table-column>
				<el-table-column :label='td("数量")' align="center">
					<template slot-scope="scope">
						<el-form-item :prop=" 'instockDetailList.' + scope.$index + '.instockNum' "
							:rules="rules.instockNum" style="margin-top:20px">
							<!-- <el-form-item >  -->
							<el-input v-model="scope.row.instockNum" />
						</el-form-item>
					</template>
				</el-table-column>
<!--				<el-table-column :label='td("店铺")'  width="250" align="center">-->
<!--					<template slot-scope="scope">-->
<!--						<el-select v-model="scope.row.shopId" @change="chanSelect(scope.row)"-->
<!--								   :placeholder='td("请选择店铺")' filterable style="width:220px">-->
<!--							<el-option v-for="(item,index) in shopList" :key="index"-->
<!--									   :label="item.name" :value="item.shopId" />-->
<!--						</el-select>-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column :label='td("店铺库存明细")'  width="250" align="center">
					<template slot-scope="scope">
						<p v-for="(shopNum, index) in scope.row.instockDetailShopList" :key="index">
							{{shopNum.shopName}} : {{shopNum.instockNum}}
						</p>
					</template>
				</el-table-column>
				<el-table-column :label='td("分配店铺库存")'  width="250" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="allotStore(scope.row)">{{td("选择店铺")}}</el-button>
					</template>
				</el-table-column>
<!--				<el-table-column prop="remark" :label='td("备注")' align="center">-->
<!--					<template slot-scope="scope">-->
<!--						<el-input v-model="scope.row.remark" />-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column :label="td('操作')" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="text" icon="el-icon-plus" @click="addSonSku(scope.row)">
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-minus" @click="delSonSku(scope.row)">
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
		<span slot="footer" class="footet">
			<el-button @click="onClose()" type="primary" plain>{{td('取 消')}}</el-button>
			<el-button @click="comit(form)" type="primary" style="margin-right: -6px;" :disabled="finish">{{td('确 定')}}</el-button>
		</span>
		<select-shop ref="selectShop" :shopList="shopList" @ok="shopOK"></select-shop>
	</el-dialog>
</template>

<script>
	import {
		getsons,
		addinorder,
		updateInOrder
	} from '@/api/warehouse'
	import {
		getLogistics
	} from '@/api/warehouse/delivery'
	import SelectShop from "./selectShop";
	import ImageUpload from "@/utils/ImageUpload/index.vue";
	export default {
		name: 'addInto',
		components: {SelectShop,ImageUpload},
		props: ['warhoureleList', 'logisticsLists', 'tableDataa', 'shopList'],
		data() {
			//验证数字的规则
			var checkEmail = (rule, value, cb) => {
				//验证数字的正则表达式
				const regEmail = /^[0-9]*$/
				if (regEmail.test(value)) {
					//合法邮箱
					return cb();
				}
				cb(new Error(this.td("请输入数字")));
			};
			return {
				// 是否显示物流名称下拉框
				logisticShow: true,
				addIntoVisible: false,
				tableKey: 1,
				select: '',
				input3: '',
				finish: false,
				timecheck: '',
				intable: [],
				checkedErpSkuSon: [],
				form: {
					state: '10953',
					instockDetailList: [],
				},
			}
		},
		//国际化页面刷新不会失效
		computed: {
			rules() {
				return {
					logisticsCompany: [{
						required: true,
						message: this.td("快递公司不能为空"),
						trigger: "blur"
					}],
					wayBillNo: [{
						required: true,
						message: this.td("物流单号不能为空"),
						trigger: "blur"
					}],
					inTime: [{
						required: true,
						message: this.td("时间不能为空"),
						trigger: "blur"
					}],
					depotId: [{
						required: true,
						message: this.td("存货仓库不能为空"),
						trigger: "blur"
					}],
					receDepot: [{
						required: true,
						message: this.td("收货仓库不能为空"),
						trigger: "blur"
					}],
					goodsName: [{
						required: true,
						message: this.td("Sku不能为空"),
						trigger: "blur"
					}],
					dispatchDf: [{
						required: true,
						message: this.td("派送方式不能为空"),
						trigger: "blur"
					}],
					inorderType: [{
						required: true,
						message: this.td("入库类型不能为空"),
						trigger: "blur"
					}]
				}
			}
		},
		methods: {
			/** 物料公司输入框、下拉框双击时间触发 */
			dblclickLogistic() {
				this.logisticShow = !this.logisticShow
			},
			/** 新增 */
			add() {
				this.logisticShow = true
				let obj = {}
				obj.sonSku = null;
				obj.shopId = null;
				obj.instockNum = null;
				this.form.instockDetailList.push(obj)
				this.addIntoVisible = true;
			},
			onClose() {
				this.form = {};
				this.form.instockDetailList = []
				this.$refs.forms.clearValidate()
				this.addIntoVisible = false
			},
			chanSelect(params) {
				// let choosenItem = this.tableDataa.filter( item => item.goodsName === params.goodsName)[0];
				// let choosenItem = this.tableDataa.filter( item => item.sonSku === params.sonSku)[0];
				for (let i = 0; i < this.form.instockDetailList.length; i++) {
					if (params.index - 1 != i && params.sonSku == this.form.instockDetailList[i].sonSku) {
						return (
							// this.$message.warning( this.td('请勿重复选择！')),this.form.instockDetailList.splice(this.form.instockDetailList.length-1)
							this.$message.warning(this.td('请勿重复选择！')), this.form.instockDetailList.splice(params
								.index - 1, 1)
						)
					}
				}
				// this.form.instockDetailList[params.index-1].sonSku = params.sonSku
				// console.log(this.form.instockDetailList);
			},
			/** 确认，根据 inorderId是否为空判断走新增接口还是编辑接口 */
			comit() {
				this.$refs.forms.validate(valid => {
					if (valid) {

						// inorderId为空走新增接口
						if (!this.form.instockDetailList) {
							this.$message.warning(this.td('入库明细不能为空！'));

							return;
						}
						for (let instockDetail of this.form.instockDetailList) {
							if (!instockDetail.sonSku) {
								this.$message.warning(this.td('seller Sku不能为空！'));

								return;
							}
							if (!instockDetail.instockNum) {
								this.$message.warning(this.td('数量不能为空！'));

								return;
							}
							if (!instockDetail.instockDetailShopList) {
								this.$message.warning(this.td('店铺库存明细不能为空！'));

								return;
							}
						}
						this.finish = true
						addinorder(this.form).then(res => {
							if (res.msg === '操作成功') {
								this.$emit('select');
								this.$message.success(this.td("新增成功"));
								this.form = {};
								this.form.instockDetailList = [];
								this.addIntoVisible = false
								this.finish = false
							} else{
								this.finish = false
								return this.$message.error(this.td(res.msg));
							}
						});
					}
				})
			},
			/**
			 * 新增一条入库明细的空字段
			 */
			addSonSku() {
				let obj = {}
				obj.sonSku = null;
				obj.shopId = null;
				obj.instockNum = null;
				this.form.instockDetailList.push(obj)
				// console.log(this.tableDataa);
			},
			/**
			 * 删除一条入库明细字段
			 * @param row
			 */
			delSonSku(row) {
				for (let i = 0; i < this.form.instockDetailList.length; i++) {
					if (row.index === this.form.instockDetailList[i].index) {
						this.form.instockDetailList.splice(i, 1);
					}
				}
			},
			rowWmsInOrderDetailIndex({
				row,
				rowIndex
			}) {
				row.index = rowIndex + 1;
			},
			AddSonlist(selection) {
				this.checkedErpSkuSon = selection.map(item => item.index);
			},
			handleDeleteSonSku() {
				if (this.checkedErpSkuSon.length == 0) {
					this.$message.warning(this.td("请先选择要删除的子SKU数据"));
				} else {
					const instockDetailList = this.form.instockDetailList;
					const checkedErpSkuSon = this.checkedErpSkuSon;
					this.form.instockDetailList = instockDetailList.filter(function(item) {
						return checkedErpSkuSon.indexOf(item.index) == -1;
					});
				}
			},
			/** 选择店铺，打开弹窗 */
			allotStore(row){
				if (!row.sonSku) {
					this.$message.warning(this.td('请先选择sku！'));
					return;
				}
				if (!row.instockNum) {
					this.$message.warning(this.td('请先输入数量！'));
					return;
				}
				this.$refs['selectShop'].add(row);
			},
			/**
			 * 选择店铺回调接口
			 * @param data
			 */
			shopOK(data) {
				for (let instockDetailListElement of this.form.instockDetailList) {
					if (instockDetailListElement.index === data.index) {
						for (let i = 0; i < data.instockDetailShopList.length; i++) {
							if (i === 0) {
								data.instockNum = data.instockDetailShopList[i].instockNum * 1;
							} else {
								data.instockNum = data.instockNum * 1 + data.instockDetailShopList[i].instockNum * 1
							}
						}
						instockDetailListElement = data;
					}
				}
				this.tableKey = !this.tableKey;
			}
		}
	}
</script>

<style scoped>
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 5px 0;
		height: 62vh;
		overflow: auto;
	}

	.el-table ::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}
</style>
