<template>
	<el-dialog :title='td("选择店铺")' :visible="addIntoVisible" @close="onClose()" width="1000px" append-to-body
		:close-on-click-modal="false">
		<el-form ref="forms" :rules="rules" :model="form" label-position="top" label-width="120px"
			style="margin-left: 20px;">
			<el-col :span="8">
				<el-form-item :label='td("数量")' label-width="125px">
					<el-input readonly style="width:96%" v-model="form.instockNum"></el-input>
				</el-form-item>
			</el-col>
			<!-- 表格 -->
			<el-table :data="form.instockDetailShopList" :row-class-name="rowWmsInOrderDetailIndex"
				@selection-change="addSonlist" tooltip-effect="dark" border
				:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
				<el-table-column type="selection" width="50" align="center" />
				<el-table-column :label="td('序号')" align="center" prop="index" width="100" />
				<el-table-column :label='td("店铺")'  width="250" align="center">
					<template slot-scope="scope">
						<el-select v-model="scope.row.shopId" @change="chanSelect(scope.row)"
								   :placeholder='td("请选择店铺")' filterable style="width:220px">
							<el-option v-for="(item,index) in shopList" :key="index"
									   :label="item.name" :value="item.shopId" />
						</el-select>
					</template>
				</el-table-column>
				<el-table-column :label='td("数量")' align="center">
					<template slot-scope="scope">
						<el-form-item :prop="'instockDetailShopList.' + scope.$index + '.instockNum'"
							:rules="rules.instockNum" style="margin-top:20px">
							<!-- <el-form-item >  -->
							<el-input v-model="scope.row.instockNum" @input="numVerify(scope.row)"/>
						</el-form-item>
					</template>
				</el-table-column>
				<el-table-column :label="td('操作')" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="text" icon="el-icon-plus" @click="addSonSku(scope.row)">
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-minus" @click="delSonSku(scope.row)">
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
		<span slot="footer" class="footet">
			<el-button @click="onClose()" type="primary" plain>{{td('取 消')}}</el-button>
			<el-button @click="comit(form)" type="primary" style="margin-right: -6px;">{{td('确 定')}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		getsons,
		addinorder,
		updateInOrder
	} from '@/api/warehouse'
	import {
		getLogistics
	} from '@/api/warehouse/delivery'


	export default {
		name: 'selectShop',
		props: ['shopList'],
		data() {
			//验证数字的规则
			var checkEmail = (rule, value, cb) => {
				//验证数字的正则表达式
				const regEmail = /^[0-9]*$/
				if (regEmail.test(value)) {
					//合法邮箱
					return cb();
				}
				cb(new Error(this.td("请输入数字")));
			};
			return {
				instockDetailList: [],
				addIntoVisible: false,
				select: '',
				input3: '',
				timecheck: '',
				intable: [],
				checkedErpSkuSon: [],
				form: {
					instockDetailShopList: [],
				},
			}
		},
		//国际化页面刷新不会失效
		computed: {
			rules() {
				return {
					logisticsCompany: [{
						required: true,
						message: this.td("快递公司不能为空"),
						trigger: "blur"
					}],
					inTime: [{
						required: true,
						message: this.td("时间不能为空"),
						trigger: "blur"
					}],
					depotId: [{
						required: true,
						message: this.td("仓库不能为空"),
						trigger: "blur"
					}],
					goodsName: [{
						required: true,
						message: this.td("Sku不能为空"),
						trigger: "blur"
					}],
					dispatchDf: [{
						required: true,
						massaeg: this.td("派送方式不能为空"),
						trigger: "blur"
					}]
				}
			}
		},
		methods: {
			/** 新增 */
			add(row) {
				this.instockDetailList = row;
				this.form.instockNum = row.instockNum;
				if (!row.instockDetailShopList) {
					let obj = {};
					obj.shopId = null;
					obj.instockNum = null;
					this.form.instockDetailShopList.push(obj);
				} else {
					this.form.instockDetailShopList = row.instockDetailShopList;
				}
				this.addIntoVisible = true;
			},
			onClose() {
				this.form = {};
				this.form.instockDetailShopList = []
				this.$refs.forms.clearValidate()
				this.addIntoVisible = false
			},
			/** 验证店铺，店铺不能重复 */
			chanSelect(params) {
				for (let i = 0; i < this.form.instockDetailShopList.length; i++) {
					if (params.index - 1 != i && params.shopId == this.form.instockDetailShopList[i].shopId) {
						this.$message.warning(this.td('请勿重复选择！'))
						params.shopId = null
						return
					}
				}
				for (let shopListElement of this.shopList) {
					if (params.shopId === shopListElement.shopId) {
						params.shopName = shopListElement.name;
					}
				}
			},
			/** 确认按钮触发事件 */
			comit() {
				let instockNumSum = 0;
				for (let shopNum of this.form.instockDetailShopList) {
					if (!shopNum.shopId) {
						this.$message.warning(this.td('店铺不能为空'));
						return;
					}
					if (!shopNum.instockNum) {
						this.$message.warning(this.td('数量不能为空'))
						return
					}
					if (instockNumSum === 0) {
						instockNumSum = shopNum.instockNum * 1;
					} else {
						instockNumSum = instockNumSum * 1 + shopNum.instockNum * 1
					}
				}
				if (instockNumSum !== this.form.instockNum * 1) {
					this.$message.warning(this.td('总数量和明细的数量总和不匹配！'));
					return;
				}
				this.instockDetailList.instockDetailShopList = this.form.instockDetailShopList;
				this.$emit('ok', this.instockDetailList)
				this.addIntoVisible = false
			},
			/**
			 * 新增一条入库明细的空字段
			 */
			addSonSku() {
				let obj = {}
				obj.sonSku = null;
				obj.shopId = null;
				obj.instockNum = null;
				this.form.instockDetailShopList.push(obj)
			},
			/**
			 * 删除一条入库明细字段
			 * @param row
			 */
			delSonSku(row) {
				for (let i = 0; i < this.form.instockDetailShopList.length; i++) {
					if (row.index === this.form.instockDetailShopList[i].index) {
						this.form.instockDetailShopList.splice(i, 1);
					}
				}
			},
			rowWmsInOrderDetailIndex({
										 row,
										 rowIndex
									 }) {
				row.index = rowIndex + 1;
			},
			addSonlist(selection) {
				this.checkedErpSkuSon = selection.map(item => item.index);
			},
			/**
			 * 数量验证（明细数量总和不能超过总数量）
			 */
			numVerify(row) {
				let instockNumSum = 0;
				for (let instockDetailShop of this.form.instockDetailShopList) {
					if (instockNumSum === 0) {
						instockNumSum = instockDetailShop.instockNum * 1;
					} else {
						instockNumSum = instockNumSum + instockDetailShop.instockNum * 1
					}
				}
				if (instockNumSum > this.form.instockNum) {
					this.$message.warning(this.td('明细数量总和不能超过总数量！'));
					row.instockNum = null;
				}
			}
		}
	}
</script>

<style scoped>
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 5px 0;
		height: 62vh;
		overflow: auto;
	}

	.el-table ::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}
</style>
