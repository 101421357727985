<template>
	<!-- 主体内容区 -->
	<div>
		<div class="search">
			<el-form :model="query" ref="query" :inline="true" label-position="left" :style="{ height: scrollerHeight }">
				<div class="box">
					<el-form-item :label="td('订单号')" prop="skuType" class="item1">
					<el-input :placeholder="td('请输入内容')" size="small" v-model="query.billNo" clearable />
					</el-form-item>
					<el-form-item :label="td('物流单号')" prop="motherName" class="item2">
						<el-input :placeholder="td('请输入内容')" size="small" v-model="query.wayBillNo" clearable />
					</el-form-item>
					<el-form-item :label="td('日期')" prop="motherName" class="item3">
						<el-date-picker v-model="query.timeScope" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" range-separator="-" :start-placeholder="td('开始日期')"
							:end-placeholder="td('结束日期')" size="small" clearable>
						</el-date-picker>
					</el-form-item>
					<el-form-item v-show="showSearchs" class="ItemShow" style="margin: 24px 0 0 0;">
						<template>
							<el-form-item :label="td('入库类型')">
								<el-select style="width:96%" v-model="query.inorderType" :placeholder="td('请选择入库类型')" clearable>
									<el-option v-for="item in tf('WMS_INORDER_TYPE')" :key="item.detailId"
											:label="td(item.langTranslate)" :value="item.detailId" />
								</el-select>
							</el-form-item>
							<el-form-item :label="td('状态')">
								<el-select style="width:96%" v-model="query.state" :placeholder="td('请选择类型')" clearable>
									<el-option v-for="item in tf('ERP_INORDER_STATE')" :key="item.detailId"
											:label="td(item.langTranslate)" :value="item.detailId" />
								</el-select>
							</el-form-item>
							<el-form-item :label="td('验货数量')">
								<el-select style="width:96%" v-model="query.compareInspeFlag" :placeholder="td('请选择验货数量')" clearable>
									<el-option v-for="item in tf('WMS_COMPARE_INSPE')" :key="item.detailId"
											:label="td(item.langTranslate)" :value="item.detailId" />
								</el-select>
							</el-form-item>
						</template>
					</el-form-item>
				</div>
				<div class="btns">
					<el-link class="btn11" type="primary" @click="toogleExpands"><i :class="icon"></i>{{ td(font) }}
					</el-link>
					<el-button type="primary" size="small" @click="getinorder">{{ td("搜索") }}</el-button>
					<el-button size="small" @click="reast" style="margin-left:16px">{{ td("重置") }}</el-button>
				</div>
			</el-form>
		</div>
		<div class="footbox">
			<div class="new_button">
				<el-button type="primary" size="small" @click="addInto">{{ td("新增入库")}}</el-button>
			</div>

			<!-- 表格 -->
			<el-table ref="table" :data="inorderList" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }"
				tooltip-effect="dark" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
				row-key="inorderId">
				<el-table-column :label='td("订单号")' prop="billNo">
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="toogleExpand(scope.row)">
							{{ scope.row.billNo }}
						</el-link>
					</template>
				</el-table-column>
				<el-table-column :label='td("存货仓库")' prop="depotId">
					<template slot-scope="scope">
						{{td(showDepotList(scope.row.depotId))}}
					</template>
				</el-table-column>
				<el-table-column :label='td("收货仓库")' prop="receDepot">
					<template slot-scope="scope">
						{{td(showDepotList(scope.row.receDepot))}}
					</template>
				</el-table-column>
				<el-table-column :label='td("状态")'>
					<template slot-scope="scope">
						<!-- {{ showStateList(scope.row.state) }} -->
						{{ tfp('ERP_INORDER_STATE',scope.row.state) }}
					</template>
				</el-table-column>
				<el-table-column :label='td("物流公司")' prop="logisticsCompany">
					<template slot-scope="scope">
			  	{{td(scope.row.logisticsCompany)}}
					</template>
				</el-table-column>
				<el-table-column :label='td("物流单号")' prop="wayBillNo" />
				<el-table-column :label='td("入库时间")' prop="inTime" />
				<el-table-column :label="td('派送方式')" prop="dispatchDf">
					<template slot-scope="scope">
						{{ tfp('ERP_INORDER_DISPATCH', scope.row.dispatchDf) }}
					</template>
				</el-table-column>
				<el-table-column :label="td('入库类型')" prop="instockType">
					<template slot-scope="scope">
						{{tfp('WMS_INORDER_TYPE', scope.row.inorderType) }}
					</template>
				</el-table-column>
				<el-table-column :label='td("创建时间")' prop="createTime" />
				<el-table-column :label='td("实际入仓时间")' prop="instockTime" width="150" />
				<el-table-column :label='td("备注")' prop="remark" />
				<el-table-column :label='td("操作")' fixed="right" width="150px">
					<template slot-scope="scope" v-if="scope.row.state == 10953">
						<el-button type="text" icon="el-icon-edit"
							@click="updateInorder(scope.row, scope.row.instockDetailList)">{{td("编辑")}}</el-button>
						<el-button type="text"
							@click="detailInorder(scope.row, scope.row.instockDetailList)">{{td("详情")}}</el-button>
						<el-button type="text" icon="el-icon-delete" @click="open(scope.row.inorderId)">{{td("删除")}}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column type="expand" width="1">
					<template slot-scope="scope">
						<el-table :data="allotDetailMap.get(scope.row.inorderId)" row-key="depotId"
							:key="detailTableKey" class="table-in-table" style="width: 90%; margin: 0 auto" border>
							<el-table-column :label='td("商品名称")' align="center" prop="goodsName" />
							<el-table-column :label='td("Seller SKU")' align="center" prop="sellerSku">
								<template slot-scope="scope">
									<el-link type="primary" :underline="false" @click="openShop(scope.row.detailId)">
										{{ scope.row.sellerSku }}
									</el-link>
								</template>
							</el-table-column>
							<el-table-column :label='td("仓库SKU")' align="center" prop="depotSku" />
							<el-table-column :label='td("商品图片")' align="center" prop="goodsImg">
								<template slot-scope="scope">
									<img :src="scope.row.goodsImg" alt="" style="width: 50px; height: 50px" />
								</template>
							</el-table-column>
							<el-table-column :label='td("采购数量")' align="center" prop="instockNum" />
							<el-table-column :label='td("入库数量")' align="center" prop="actualNum" />
							<el-table-column :label='td("备注")' prop="remark" />
						</el-table>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<pagination :total="td(total)" :page.sync="query.pageNum" :limit.sync="query.pageSize"
			@pagination="getinorder" />


		<!-- 弹窗组件 -->
		<addInto ref="addIntl" :warhoureleList="warhoureleList" :tableDataa="tableDataa"
			:logisticsLists="logisticsLists" :shopList="shopList" @select="getinorder" />
		<updatelnto ref="updatelnto" :updateIntoVisible.sync="updateIntoDig" :getinorder="getinorder" :updateorderList="updateorderList"
			:tableData="tableData" :warhoureleList="warhoureleList" :logisticsLists="logisticsLists" />
		<detailInto ref="detailInto" :updateIntoVisible.sync="detailIntoDig" :getinorder="getinorder" :updateorderList="updateorderList"
			:tableData="tableData" :warhoureleList="warhoureleList" :logisticsLists="logisticsLists" />
		<shop-inventory-detail ref="shopInventory"></shop-inventory-detail>
	</div>
</template>

<script>
	import addInto from "./dialog/addInto.vue";
	import updatelnto from "./dialog/updatelnto.vue";
	import detailInto from "./dialog/detailInto.vue";
	import Cookies from "js-cookie";
	import { getShopMsg } from "@/api/store";
	import {
		getinordersa,
		delInorder,
		getinorders,
		getListtt,
		getsons
	} from "@/api/warehouse/index"

	import {
		getstatus
	} from "@/api/model/apis.js"
	import {
		getLogistics
	} from '@/api/warehouse/delivery'
	import ShopInventoryDetail from "./dialog/shopInventoryDetail";

	export default {
		name: "warehouseing",
		components: {
			ShopInventoryDetail,
			addInto,
			updatelnto,
			detailInto
		},
		data() {
			return {
				shopList: [],    // 店铺信息
				total: 0,
				query: {
					billNo: null,
					state: null,
					shopId: null,
					inTime: null,
					skuInfo: null,
					wayBillNo: null,
					timeScope: [],
					pageNum: 1,
					pageSize: 10,
				},
				settingDig: false,
				addIntoDig: false,
				updateIntoDig: false,
				detailIntoDig: false,
				inorderList: [],
				updateorderList: [],
				warhoureleList: [],
				tableData: [],
				tableDataa: [],
				stateList: [],
				logisticsLists: [],
				detailTableKey: false,
				allotDetailMap: new Map(),
				font: "更多",
				icon: "el-icon-arrow-down",
				showSearchs: false,
			};
		},
		created() {
			this.getinorder()
			this.getdeptList()
			// this.getState()
			this.logisticsList()
			this.getSonlist()
			this.getShopList()
		},
		computed: {
			//显示仓库名字
			showDepotList: function() {
				return function(number) {
					for (let i = 0; i < this.warhoureleList.length; i++) {
						if (number == this.warhoureleList[i].id) {
							return this.warhoureleList[i].depotName;
						}
					}
				};
			},
			scrollerHeight: function() {
				return this.showSearchs ? 160 + "px" : 80 + "px";
			},
			//显示状态名字
			// showStateList: function () {
			//   return function (number) {
			//     for (let i = 0; i < this.stateList.length; i++) {
			//       if (number == this.stateList[i].detailId) {
			//         return this.stateList[i].fl;
			//       }
			//     }
			//   }
			// },
		},
		methods: {
			toogleExpands() {
				if (this.showSearchs) {
					this.showSearchs = !this.showSearchs;
					this.icon = "el-icon-arrow-down";
					this.font = this.td("更多")
				} else {
					this.showSearchs = !this.showSearchs;
					this.font = this.td("收起")
					this.icon = "el-icon-arrow-up";
				}
			},
			//查询批次信息
			getinorder() {
				getinordersa(this.query).then(res => {
					this.inorderList = res.rows;
					this.total = res.total
				});
			},
			//查询仓库信息
			getdeptList() {
				getListtt().then((res) => {
					this.warhoureleList = res.data;
				});
			},
			// 查询店铺信息
			getShopList() {
				getShopMsg().then((res) =>{
					this.shopList = res.rows
				})
			},
			// getState() {
			//   let lang = Cookies.get("langId");
			//   getstatus(lang, "ERP_INORDER_STATE").then((response) => {
			//     this.stateList = response.data;
			//   });
			// },
			getSonlist() {
				getsons().then(res => {
					this.tableDataa = res.rows;
				})
			},
			logisticsList() {
				getLogistics().then(res => {
					this.logisticsLists = res.rows
				})
			},
			//重置
			reast() {
				this.query = {
					pageNum: 1,
					pageSize: 10,
				}
				this.getinorder()
			},
			updateInorder(data) {
				this.updateorderList = JSON.parse(JSON.stringify(data));
				// this.tableData = data.inorderId;
				getinorders(data.inorderId).then(res => {
					this.tableData = res.rows
				})
				this.updateIntoDig = true;
				this.$refs['updatelnto'].edit();
			},
			detailInorder(data){
				this.updateorderList = JSON.parse(JSON.stringify(data));
				// this.tableData = data.inorderId;
				getinorders(data.inorderId).then(res => {
					this.tableData = res.rows
				})
				this.detailIntoDig = true;
				this.$refs['detailInto'].edit();
			},
			async open(id) {
				const confirmResult = await this.$confirm(this.td("是否删除入库单?"), this.td("提示"), {
					confirmButtonText: this.td("确定"),
					cancelButtonText: this.td("取消"),
					type: "warning",
				}).catch((err) => err);
				if (confirmResult !== "confirm") return this.$message.info(this.td("已取消删除"));
				delInorder(id).then((res) => {
					if (res.code !== 200) {
						return this.$message.error(this.td("删除失败"));
					}
					this.$message.success(this.td("删除成功"));
					this.getinorder();
				});
			},
			getAllotDetail(row) {
				//根据 deopid 获取子表
				getinorders(row).then(res => {
					// 使用 map 结构的方式保存子表
					this.allotDetailMap.set(row, res.rows);
					this.detailTableKey = !this.detailTableKey;
				})
			},
			//点击母商品名，显示子SKU
			toogleExpand(val) {
				let $table = this.$refs.table;
				$table.toggleRowExpansion(val);
				this.getAllotDetail(val.inorderId)
			},
			addInto() {
				this.addIntoDig = true
				this.$refs['addIntl'].add();
			},
			/**
			 * 打开库存明细弹窗
			 * @param detailId
			 */
			openShop(detailId) {
				this.$refs['shopInventory'].show(detailId);
			}
		}
	}
</script>

<style scoped>

	.search {
		position: relative;
		background-color: #fff;
	}

	.search .box {
		position: absolute;
		top: 20px;
	}

	.search .el-form {
		padding: 0 16px;
		color: #333333;
		margin-right: 0;
		border-bottom: 16px solid #F1F3FF;
	}

	.search .el-form-item {
		margin-bottom: 0;
		margin-right: 0;
	}

	.search ::v-deep .el-form-item__label {
		margin-right: 24px;
		padding: 0;
	}

	.search ::v-deep .el-input__inner {
		width: 220px;
	}

	.search ::v-deep .el-input__inner {
		height: 32px;
	}

	.el-form-item:not(:first-child) {
		margin-left: 32px;
	}


	.btns {
		position: absolute;
		top: 24px;
		right: 16px;
	}

	.btns .el-button {
		width: 74px;
		height: 32px;
	}

	.btns .btn11,
	.btn22 {
		margin-right: 16px;
	}
	.serch .el-form {
		width: 100%;
		height: 80px;
		box-sizing: border-box;
		padding: 0 0 0 16px;
		background-color: #fff;
	}

	.serch .el-form-item {
		transform: translateY(20px);
		margin-right: 0;
	}

	.serch ::v-deep .el-form-item__label:nth-child(n-2) {
		margin: 0 24px 0 32px;
		padding: 0;
	}

	.serch .el-form-item:nth-child(1) ::v-deep .el-form-item__label:nth-child(n-2) {
		margin: 0 24px 0 0px;
		padding: 0;
	}

	.serch ::v-deep .el-input__inner {
		width: 220px;
	}

	.footbox {
		background-color: #fff;
		margin-top: 16px;
	}

	.new_button {
		float: right;
		transform: translate(-16px, 24px);
		height: 80px;
	}

	.serch .el-button {
		width: 74px;
		height: 32px;
	}

	.el-table {
		padding: 0 16px;
	}

	@media screen and (max-width: 1800px) {
		.serch ::v-deep .el-input__inner {
			width: 150px;
			height: 32px;
		}

	}
	/* 110% */
	@media screen and (max-width: 1800px) {
		.search ::v-deep .el-input__inner {
			width: 200px;
		}

		.search ::v-deep .el-form-item__label {
			margin-right: 12px;
		}

		.el-form-item:not(:first-child) {
			margin-left: 16px;
		}

		.btns {
			position: absolute;
			top: 24px;
			right: 0px;
		}

		.btns .btn11 {
			margin-right: 16px;
			/* transform: translate(23px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(23px, 0) scale(0.65, 0.65);
      -moz-transform: translate(23px, 0) scale(0.65, 0.65);
      -o-transform: translate(23px, 0) scale(0.65, 0.65); */
		}

		.btns .btn22 {
			margin-right: 16px;
			/* transform: translate(18px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(18px, 0) scale(0.65, 0.65);
      -moz-transform: translate(18px, 0) scale(0.65, 0.65);
      -o-transform: translate(18px, 0) scale(0.65, 0.65); */
		}

		.btns .btn33 {
			margin-right: 16px;
			/* transform: scale(0.65, 0.65);
      -webkit-transform: scale(0.65, 0.65);
      -moz-transform: scale(0.65, 0.65);
      -o-transform: scale(0.65, 0.65); */
		}

		/* .btn1 {
      transform: translate(-15px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
      -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
      -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    }
    .btn2 {
      transform: translate(20px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(20px, 0) scale(0.65, 0.65);
      -moz-transform: translate(20px, 0) scale(0.65, 0.65);
      -o-transform: translate(20px, 0) scale(0.65, 0.65);
    }
    .btn3 {
      transform: translate(-15px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(60px, 0) scale(0.65, 0.65);
      -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
      -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    } */
	}

	/* 125% */
	@media screen and (max-width: 1620px) {
		.search ::v-deep .el-input__inner {
			width: 160px;
		}

		.search ::v-deep .el-form-item__label {
			margin-right: 12px;
		}

		.el-form-item:not(:first-child) {
			margin-left: 16px;
		}

		.btns {
			position: absolute;
			top: 24px;
			right: 0px;
		}

		.btns .btn11 {
			margin-right: 0px;
			transform: translate(23px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(10px, 0) scale(0.85, 0.85);
			-moz-transform: translate(23px, 0) scale(0.85, 0.85);
			-o-transform: translate(23px, 0) scale(0.85, 0.85);
		}

		.btns .btn22 {
			margin-right: 16px;
			transform: translate(16px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(16px, 0) scale(0.85, 0.85);
			-moz-transform: translate(16px, 0) scale(0.85, 0.85);
			-o-transform: translate(16px, 0) scale(0.85, 0.85);
		}

		.btns .btn33 {
			transform: scale(0.85, 0.85);
			-webkit-transform: translate(0px, 0) scale(0.85, 0.85);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}

		.btn1 {
			transform: translate(-5px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(-5px, 0) scale(0.85, 0.85);
			-moz-transform: translate(-5px, 0) scale(0.85, 0.85);
			-o-transform: translate(-5px, 0) scale(0.85, 0.85);
		}

		.btn2 {
			transform: translate(20px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(7px, 0) scale(0.85, 0.85);
			-moz-transform: translate(20px, 0) scale(0.85, 0.85);
			-o-transform: translate(20px, 0) scale(0.85, 0.85);
		}

		.btn3 {
			transform: translate(28px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(28px, 0) scale(0.85, 0.85);
			-moz-transform: translate(28px, 0) scale(0.85, 0.85);
			-o-transform: translate(28px, 0) scale(0.85, 0.85);
		}
	}

	/* 150% */
	@media screen and (max-width: 1500px) {
		.search ::v-deep .el-input__inner {
			width: 160px;
		}

		.search ::v-deep .el-form-item__label {
			margin-right: 16px;
		}

		.el-form-item:not(:first-child) {
			margin-left: 0px;
		}

		.item1 {
			transform: translate(-30px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
			-moz-transform: translate(-30px, 0) scale(0.85, 0.85);
			-o-transform: translate(-30px, 0) scale(0.85, 0.85);
		}

		.item2 {
			transform: translate(-67px, 0) scale(0.75, 0.75);
			-webkit-transform: translate(-67px, 0) scale(0.75, 0.75);
			-moz-transform: translate(-67px, 0) scale(0.75, 0.75);
			-o-transform: translate(-67px, 0) scale(0.75, 0.75);
		}

		.item3 {
			transform: translate(-104px, 0) scale(0.75, 0.75);
			-webkit-transform: translate(-104px, 0) scale(0.75, 0.75);
			-moz-transform: translate(-104px, 0) scale(0.75, 0.75);
			-o-transform: translate(-104px, 0) scale(0.75, 0.75);
		}

		.item4 {
			transform: translate(-143px, 0) scale(0.75, 0.75);
			-webkit-transform: translate(-143px, 0) scale(0.75, 0.75);
			-moz-transform: translate(-143px, 0) scale(0.75, 0.75);
			-o-transform: translate(-143px, 0) scale(0.75, 0.75);
		}

		.btns {
			position: absolute;
			top: 24px;
			right: 0px;
		}

		.btns .btn11 {
			margin-right: 0px;
			transform: translate(35px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(35px, 0) scale(0.65, 0.65);
			-moz-transform: translate(35px, 0) scale(0.65, 0.65);
			-o-transform: translate(35px, 0) scale(0.65, 0.65);
		}

		.btns .btn22 {
			margin-right: 0px;
			transform: translate(28px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(28px, 0) scale(0.65, 0.65);
			-moz-transform: translate(28px, 0) scale(0.65, 0.65);
			-o-transform: translate(28px, 0) scale(0.65, 0.65);
		}

		.btns .btn33 {
			transform: translate(13px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(13px, 0) scale(0.65, 0.65);
			-moz-transform: translate(13px, 0) scale(0.65, 0.65);
			-o-transform: translate(13px, 0) scale(0.65, 0.65);
		}

		.btn1 {
			transform: translate(-15px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
			-moz-transform: translate(-15px, 0) scale(0.65, 0.65);
			-o-transform: translate(-15px, 0) scale(0.65, 0.65);
		}

		.btn2 {
			transform: translate(18px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(18px, 0) scale(0.65, 0.65);
			-moz-transform: translate(18px, 0) scale(0.65, 0.65);
			-o-transform: translate(18px, 0) scale(0.65, 0.65);
		}

		.btn3 {
			transform: translate(60px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(60px, 0) scale(0.65, 0.65);
			-moz-transform: translate(60px, 0) scale(0.65, 0.65);
			-o-transform: translate(60px, 0) scale(0.65, 0.65);

		}
	}

	@media screen and (min-width: 2510px) {
		.item4 {
			margin-right: 32px !important;
		}
	}

	@media screen and (min-width: 2561px) {
		.ItemShow {
			margin: 0 0 0 0px !important;
		}
	}
</style>
