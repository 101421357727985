import request from '@/utils/request'
import { getToken } from "@/utils/auth"

export function getShopee(data) {
  return request({
    url:'/shopee/shopee/add_erpShopUser',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'post',
    data:data
  }) 
}

export function getUrl() {
  return request({
    url:'/shopee/shopee/getUrl',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  }) 
}

export function getLazada(data) {
  return request({
    url:'/lazada/ShopUser/callback?code=' + data,
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  }) 
}

//展示------------------
export function getShopMsg(data) {
  return request({
    url:'/erp/shop/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
    params: data
  }) 
}
//跳转取消shopee授权
export function cancelShopee() {
  return request({
    url:'/shopee/shopee/cancel_auth',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  }) 
}
//传入ID取消shopee授权
export function cancelShopeeAuth(data) {
  return request({
    url:'/shopee/shopee/cancel_erpShop?shopId='+data,
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
	// data:data
  }) 
}
//取消Lazada授权
export function cancelLazadaAuth(data) {
  return request({
    url:'/lazada/ShopUser/revoke',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  }) 
}

export function adddepotId(data) {
  return request({
    url:'/erp/api/order/addDepot',
    headers: {
      "Authorization": "Bearer " + getToken(),
    },
    method: 'put',
    data:data
  }) 
}